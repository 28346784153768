export async function postDocumentIntelligence(endpoint, options) {
  return await fetch(endpoint, options).then((response) => {
    if (response.status >= 400) {
      if(response.status == 400) {
        return { status: "notOK", message: "Incorrect Format" };
      }
      return { status: "notOK", message: response.message };
    }
    return response.headers.get("Apim-Request-Id");
  });
}

export async function getDocumentIntelligence(endpoint, options) {
  return await fetch(endpoint, options).then((response) => {
    if (response.code >= 400) {
      return { status: "notOK", message: response.message };
    }
    return response.json();
  });
}
