import {
  fileName,
  fileOrNot,
  fileBase64,
  documentID,
  extractedText,
  extractedStatus,
  fileFormat,
  fileSize,
  fileDialogOpen,
  fileDialogContent,
  fileNameRemaining,
} from "../action";

const initialState = {
  fileOrNot: false,
  fileName: "",
  fileBase64: "",
  documentID: "",
  extractedStatus: "",
  extractedText: "",
  fileFormat: "",
  fileSize: "",
  fileDialogOpen:false,
  fileNameRemaining: false,
  fileDialogContent:{},
};

export default function file(state = initialState, action) {
  switch (action.type) {
    case fileName:
      return { ...state, fileName: action.payload };
    case fileOrNot:
      return { ...state, fileOrNot: action.payload };
    case fileBase64:
      return { ...state, fileBase64: action.payload.split(",")[1] };
    case documentID:
      return { ...state, documentID: action.payload };
    case extractedStatus:
      return { ...state, extractedStatus: action.payload };
    case extractedText:
      return { ...state, extractedText: action.payload };
    case fileFormat:
      return { ...state, fileFormat: action.payload };
    case fileSize:
      return { ...state, fileSize: action.payload };
    case fileDialogOpen:
      return { ...state, fileDialogOpen: action.payload };
    case fileDialogContent:
      return { ...state, fileDialogContent: action.payload };
    case fileNameRemaining:
      return { ...state, fileNameRemaining: action.payload };
    default:
      return state;
  }
}
