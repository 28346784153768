import React from "react";
import styled from "@emotion/styled";
import Button from "@mui/material/Button";

function MenuButton({ open, handleClick, gptModel, studentOrNot, blur }) {

  return (
    <Button
      id="gpt-model-choice"
      name="gpt-model-choice"
      aria-label="gpt-model-choice"
      title="gpt-model-choice"
      aria-controls={open ? "basic-menu" : undefined}
      aria-haspopup="true"
      aria-expanded={open ? "true" : undefined}
      onClick={handleClick}
      sx={{
        backgroundColor: !blur? "#D3D3D3": gptModel != "4" ? "#A730D0" : "#218514",
        color: "white",
        borderRadius: "0px 20px 20px 0px",
        height: "41px",
        minWidth: "30px",
        "&:hover": {
          backgroundColor: gptModel != "4" ? "#c8a5f8" : "rgb(30 162 130)",
        },
        "&::after": {
          display: "inline-block",
          marginLeft: "0.255em",
          verticalAlign: "0.255em",
          content: '""',
          borderTop: "0.3em solid",
          borderRight: "0.3em solid transparent",
          borderBottom: 0,
          borderLeft: "0.3em solid transparent",
          color: "white",
        },
      }}
    ></Button>
  );
}

export default React.memo(MenuButton);
