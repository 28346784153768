import React, { useState } from "react";
import { useMsal } from "@azure/msal-react";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { loginRequest } from "../../authConfig";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "../../authConfig";
import { redirect, useNavigate } from "react-router-dom";
import { ChatHistContext } from "../../context";
import { getTokenResponse } from "../../utils/MsGraphApiCall";

const msalInstance = new PublicClientApplication(msalConfig);

export const SignInButton = () => {
  const { setToken } = React.useContext(ChatHistContext);
  const { instance } = useMsal();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleLogin = async (loginType) => {
    setAnchorEl(null);
    if (loginType == "popup") {
      msalInstance
        .loginPopup(loginRequest)
        .then(async (res) => {
          let token = await getTokenResponse();
          await msalInstance.setActiveAccount(res.account);
          setToken(token);
          window.location = "/home";
        })
        .catch((error) => {
          console.error(error);
        });
    } else if (loginType == "redirect") {
      instance.loginRedirect(loginRequest);
    }
  };

  return (
    <div>
      <Button
        onClick={() => handleLogin("popup")}
        //bgcolor="success"
        variant="contained"
        sx={{
          color: "white",
          backgroundColor: "#28a745",
          width: "180px",
          borderRadius: "30px",
          fontWeight: "bold",
          fontFamily: "Roboto",
          background: "rgb(30 162 130)",
          cursor: "pointer",
          "&:hover": {
            background: "rgb(92 189 165 / 65%)",
          },
        }}
      >
        Sign In
      </Button>
    </div>
  );
};
