import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { useSelector } from "react-redux";
import parse from "html-react-parser";
import Grid from "@mui/material/Grid";
import { studentQuota, staffQuota } from "../../utils/quotaInfo";

export default function Dialogue({ open, handleClose }) {
  const { fontSize } = useSelector((state) => state.setting);
  const { studentOrNot } = useSelector((state) => state.token);

  return (
    <Dialog
      onClose={() => {
        handleClose(false);
      }}
      open={open}
      sx={{
        fontFamily: "Roboto",
        fontSize: fontSize,
        color: "#f9f9f9",
        "& .MuiPaper-root": { borderRadius: "20px" },
        "& h2": { fontWeight: "bold", padding: "30px 25px 0px 40px" },
      }}
    >
      <DialogTitle>
        <Grid sx={{ display: "flex", justifyContent: "space-between" }}>
          <Grid>ChatGPT Services</Grid>{" "}
          <Grid
            onClick={() => {
              handleClose(false);
            }}
            sx={{ cursor: "pointer" }}
          >
            <img src={"/close.svg"} className="App-logo" alt="logo" />
          </Grid>
        </Grid>
      </DialogTitle>
      <List
        sx={{
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: fontSize.replace("px", "") * 1 - 2 + "px",
          lineHeight: "21px",
          padding: "0px 40px 25px 40px",
          color: "#777",
        }}
      >
        <ListItem disableGutters>
          Azure OpenAI processes text by breaking it down into tokens. Tokens
          can be words or just chunks of characters. For example, the word
          “hamburger” gets broken up into the tokens “ham”, “bur” and “ger”,
          while a short and common word like “pear” is a single token. Many
          tokens start with a whitespace, for example “ hello” and “ bye”.
        </ListItem>

        <ListItem disableGutters>
          The total number of tokens processed in a given request depends on the
          length of your input, output and request parameters. The quantity of
          tokens being processed will also affect your response latency and
          throughput for the models.
        </ListItem>

        <ListItem
          disableGutters
          sx={{ paddingTop: "20px", width: "100%", overflowX: "auto" }}
        >
          {studentOrNot ? studentQuota(fontSize) : staffQuota(fontSize)}
        </ListItem>
      </List>
    </Dialog>
  );
}
