import React from "react";
import { Navigate } from "react-router-dom";
import { getTokenResponse } from "../utils/MsGraphApiCall";
import { ChatHistContext } from "../context";
import { validTenants } from "../utils/AuthTools";

function ProtectedRoute({ children }) {
  const { token, setToken } = React.useContext(ChatHistContext);

  if (!token) {
    return <Navigate to="/" replace />;
  }
  if(token != "" && token != null && validTenants.indexOf(token.tenantId)<=-1){
    return <Navigate to="/notAllowed" replace />;
  }
  return children;
}
export default ProtectedRoute;
