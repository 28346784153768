export const selectingThreeSuggestions = () => {
  let suggestionTemplate = [
    "Tell me more",
    "Can you provide more details about how AI language models assist with content generation?",
    "What are some potential challenges or limitations when using AI language models for content generation?",
    "What is the purpose of this document?",
  ];
  let randName = [];
  do {
    randName[randName.length] = suggestionTemplate.splice(
      Math.floor(Math.random() * suggestionTemplate.length),
      1
    )[0];
  } while (randName.length < 3);

  return randName;
}
