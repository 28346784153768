import { Routes, Route, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { MsalProvider } from "@azure/msal-react";
import { CustomNavigationClient } from "./utils/NavigationClient";

import { PageLayout } from "./layout/PageLayout";
import { Home } from "./pages/Home";
import { Auth } from "./pages/Auth";
import NotAllowed from "./pages/NotAllowed";
import { useSelector } from "react-redux";
import ProtectedRoute from "./layout/ProtectedRoute";
import useWindowSize from "./hook/UseWindowSize";
import { changeFontSize } from "./utils/Tools";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from "react";

function App({ pca }) {
  const { fontSize } = useSelector((state) => state.setting);

  useEffect(() => {
    changeFontSize(document.body, fontSize);
  }, [fontSize]);

  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);
  const size = useWindowSize();
  const theme = createTheme({
    custom: {
      barHeight: {
        height: "70px",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MsalProvider instance={pca}>
        <PageLayout>
          <Grid container justifyContent="center" height={size.height - 110}>
            <Pages />
            <ToastContainer />
          </Grid>
        </PageLayout>
      </MsalProvider>
    </ThemeProvider>
  );
}

function Pages() {
  return (
    <Routes>
      <Route exact path="/" element={<Auth />} />
      <Route exact path="/notAllowed" element={<NotAllowed />} />
      <Route
        path="/home"
        element={
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}

export default App;
