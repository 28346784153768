import { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import Button from "@mui/material/Button";
import { loginRequest } from "../../authConfig";
import { useSelector, useDispatch } from "react-redux";
import { setIsMenuOpend } from "../../action";
import useWindowSize from "../../hook/UseWindowSize";

export default function SettingButton({ setOpenMenu }) {
  //const { instance } = useMsal();
  const size = useWindowSize();
  const dispatch = useDispatch();
  const openMenu = useSelector((state) => state.setting.isMenuOpen);
  const fontSize = useSelector((state) => state.setting.fontSize);
  //useEffect(()=>{console.log(openMenu)},[openMenu])
  return (
    <div>
      <Button
        onClick={() => {
          dispatch(setIsMenuOpend(!openMenu));
        }}
        bg="success"
        variant="contained"
        sx={{
          color: "white",
          backgroundColor: "rgb(30 162 130)",
          fontSize: fontSize,
          padding: size.width < 500 ? "5px 10px" : "10px 20px 10px 20px",
          borderRadius: "15px",
          "&:hover": {
            background: "rgb(92 189 165 / 65%)",
            color: "white",
          },
        }}
      >
        Settings
      </Button>
    </div>
  );
}
