import React, { useEffect, useRef } from "react";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import FilePreview from "./FilePreview";
import CloseIcon from "@mui/icons-material/Close";

export default function FilePreviewDialogue({
  fileDialogOpen = false,
  setFileDialogOpen = null,
  type = "pdf",
  fileBase64,
  fileName,
  fileSize,
  fontSize,
  windowSize,
}) {
  return (
    <Dialog
      onClose={() => {
        setFileDialogOpen(false);
      }}
      open={fileDialogOpen}
      sx={{
        fontFamily: "Roboto",
        fontSize: fontSize.replace("px", "") * 1 - 3 + "px",
        color: "#f9f9f9",
        display: "flex",
        flexDirection: "column",
        "& .MuiPaper-root": {
          overflowY: "hidden!important",
          borderRadius: "0px!important",
          width: windowSize.width > 767 ? "75%" : "95%",
          maxWidth: "1200px",
          height: windowSize.height * 0.9 + "px",
          background: "#D9D9D9",
        },
        "& h2": { fontWeight: "bold", padding: "30px 25px 0px 40px" },
      }}
    >
      <Grid
        sx={{
          background: "#FBFBFB",
          height: "fit-content",
          display: "flex",
          alignItems: "flex-start",
          padding: "10px 30px",
          justifyContent: "space-between",
        }}
      >
        <Grid sx={{ display: "none" }}></Grid>
        <Grid sx={{ display: "none" }}></Grid>
        <Grid>
          <Grid sx={{ display: "flex", flexDirection: "column" }}>
            <Grid sx={{ color: "#545454" }}>
              <b>{fileName}</b>
            </Grid>

            <Grid
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                color: "#9E9E9E",
              }}
            >
              <Grid sx={{ marginRight: "10px" }}>{type.toUpperCase()}</Grid>
              <Grid>{fileSize}</Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          {" "}
          <CloseIcon
            onClick={() => {
              setFileDialogOpen(false);
            }}
          />
        </Grid>
      </Grid>
      <Grid
        sx={{ padding: "20px", overflowY: "auto" }}
        className={type == "xlsx" ? "excel-class" : ""}
      >
        <FilePreview type={type} fileBase64={fileBase64} fileName={fileName} windowSize={windowSize.height * 0.78}/>
      </Grid>
    </Dialog>
  );
}
