import React, { useState, useCallback } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import WelcomeName from "./WelcomeName";
import SignInSignOutButton from "./Button/SignInSignOutButton";
import { Link as RouterLink } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import styled from "@emotion/styled";
import SideBar from "./SideBar";
import SettingButton from "./Button/SettingButton";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { ChatHistContext } from "../context";

import useWindowSize from "../hook/UseWindowSize";

const NavContainer = styled.div`
  height: 10%;
  min-height: 70px;
  margin: 0;
`;

const NewToolbar = styled(Toolbar)`
  height: 10%;
  min-height: 70px !important;
  background: white;
  padding: 30px 15px 30px 15px;
`;

const NavBar = () => {
  const size = useWindowSize();
  const theme = useTheme();
  const { backToAuth } = React.useContext(ChatHistContext);
  return (
    <>
      <UnauthenticatedTemplate>
        <NavContainer>
          <AppBar position="static" elevation={0}>
            <NewToolbar>
              <Typography sx={{ flexGrow: 1 }}>
                <Link
                  component={RouterLink}
                  to="/"
                  sx={{
                    "& > img": { width: size.width > 620 ? "250px" : "280px" },
                  }}
                >
                  <img src="hku-logo.svg" alt="example" />
                </Link>
              </Typography>
              <WelcomeName />
              {/*<SignInSignOutButton />*/}
            </NewToolbar>
          </AppBar>
        </NavContainer>
      </UnauthenticatedTemplate>

      <AuthenticatedTemplate>
        <NavContainer>
          <AppBar position="static" elevation={0}>
            <NewToolbar>
              <Typography sx={{ flexGrow: 1 }}>
                <Link
                  component={RouterLink}
                  to="/"
                  sx={{
                    "& > img": {
                      width:
                        size.width > 620
                          ? "250px"
                          : size.width < 500
                          ? "210px"
                          : "240px",
                    },
                  }}
                >
                  <img src="hku-logo.svg" alt="example" />
                </Link>
              </Typography>
              <SideBar />
              {!backToAuth && <SettingButton />}
            </NewToolbar>
          </AppBar>
        </NavContainer>
      </AuthenticatedTemplate>
    </>
  );
};

export default NavBar;
