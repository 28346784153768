import * as React from "react";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import useWindowSize from "../hook/UseWindowSize";

export const UploadingAlert = ({message = "Processing...", backgroundColor = "#545454"}) => {

  const size = useWindowSize();
  return (
    <Grid
      sx={{
        width: "100%",
        borderLeft:  size.width < 500?"0px": "1px solid #D3D3D3",
        borderRight:  size.width < 500?"0px":"1px solid #D3D3D3",
        
      }}
    >
      <Alert
        sx={{
          width: "fit-content",
          fontFamily: "Roboto",
          margin: "20px auto 10px auto",
          background: backgroundColor,
          borderRadius: "30px",
          color: "white",
          fontWeight: "bold",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "& .MuiAlert-message": {
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
          },
          "& svg": {
            cursor: "pointer",
          },
          "& .MuiAlert-icon": {
            display: "none",
          },
          "& > .MuiAlert-message": {
            paddingTop: "0px",
            paddingBottom: "0px",
            color:"white",
            fontSize: "12px"
          },
          "& > .MuiAlert-message > .MuiCircularProgress-root": {
            color:"white",
            width: "15px!important",
            height: "15px!important",
            marginRight:"5px"
          },
        }}
        severity="warning"
      >
        {message == "Processing..." && <CircularProgress/> }{"  "}
        {message}
        
      </Alert>
    </Grid>
  );
};
