import { systemMessageContent,defaultState,systemMessageTemplate,reset } from "../action";

const initialState = {
  systemMessage:"Default",
  content: "You are an AI assistant that helps people find information.",
  role: "",
};

export default function systemMessage(state = initialState, action) {
  switch (action.type) {
    case systemMessageTemplate:
      return { ...state, systemMessage: action.payload, role: "system" };
    case systemMessageContent:
      return { ...state, content: action.payload, role: "system" };
    case defaultState:
      return { ...state, ...initialState };
    default:
      return state;
  }
}
