export function getRemainingDays() {
  var date = new Date();
  var time = new Date(date.getTime());
  time.setMonth(date.getMonth() + 1);
  time.setDate(0);
  var days =
    time.getDate() > date.getDate() ? time.getDate() - date.getDate() : 0;
  return days;
}

const payloadGenerator = (chatHist, userQuestion, gptAnswer) => {
  var content = "",
    i = 0;
  var contentIndex = 0;
  while (i < chatHist[0].length) {
    if (
      !chatHist[0][i].hasOwnProperty("endChatName") &&
      !chatHist[0][i].hasOwnProperty("startChatName")
    ) {
      content += chatHist[0][i].name + " [" + chatHist[0][i].dateTime + "]:\n";
      content +=
        chatHist[0][i].question + "\n————————————————————————————————————\n";
      content +=
        chatHist[1][contentIndex].name +
        " [" +
        chatHist[1][contentIndex].dateTime +
        "]:\n";
      content +=
        //parse(chatHist[1][contentIndex].content).props.children +
        chatHist[1][contentIndex].content
          .replaceAll("&lt;", "<")
          .replaceAll("&gt;", ">") + "\n————————————————————————————————————\n";
      contentIndex++;
    } else {
      if (chatHist[0][i].hasOwnProperty("endChatName")) {
        content +=
          "\n——————————————————" +
          chatHist[0][i].endChatName +
          "  " +
          chatHist[0][i].endChatTime +
          "——————————————————\n";
      }
      if (chatHist[0][i].hasOwnProperty("startChatName")) {
        content +=
          "\n——————————————————" + "Chat started" + "——————————————————\n";
      }
    }

    i++;
  }
  i = 0;
  contentIndex = 0;
  while (i < userQuestion.length) {
    if (
      !userQuestion[i].hasOwnProperty("endChatName") &&
      !userQuestion[i].hasOwnProperty("startChatName")
    ) {
      content +=
        userQuestion[i].name + " [" + userQuestion[i].dateTime + "]:\n";
      content +=
        userQuestion[i].question + "\n————————————————————————————————————\n";
      content +=
        gptAnswer[contentIndex].name +
        " [" +
        gptAnswer[contentIndex].dateTime +
        "]:\n";
      content +=
        //parse(chatHist[1][contentIndex].content).props.children +
        gptAnswer[contentIndex].content
          .replaceAll("&lt;", "<")
          .replaceAll("&gt;", ">") + "\n————————————————————————————————————\n";
      contentIndex++;
    } else {
      if (userQuestion[i].hasOwnProperty("endChatName")) {
        content +=
          "\n——————————————————" +
          userQuestion[i].endChatName +
          "  " +
          userQuestion[i].endChatTime +
          "——————————————————\n";
      }
      if (userQuestion[i].hasOwnProperty("startChatName")) {
        content +=
          "\n——————————————————" + "Chat started" + "——————————————————\n";
      }
    }
    i++;
  }
  return content;
};

export async function copy(chatHist, userQuestion, gptAnswer) {
  let content = payloadGenerator(chatHist, userQuestion, gptAnswer);

  navigator.clipboard.writeText(content).then(() => {
    //alert("Cpoying to clipboard successfully");
  });
}

export async function emailSend(chatHist, userQuestion, gptAnswer) {
  console.clear();
  var email = "";
  var subject = "HKU ChatGPT Chat History";
  var content = "HKU ChatGPT Chat History\n\n",
    i = 0;
  content += payloadGenerator(chatHist, userQuestion, gptAnswer);
  window.open(
    "mailto:" +
      email +
      "?subject=" +
      subject +
      "&body=" +
      encodeURIComponent(content),
    "_blank"
  );
  setTimeout(function () {
    console.clear();
  }, 100); //simple async

  return false;
}

export function changeFontSize(element, size) {
  var currentSize = window
    .getComputedStyle(element, null)
    .getPropertyValue("font-size");
  if (currentSize) {
    //currentSize = parseFloat(currentSize.replace("px",""));
    element.style.fontSize = size + "px!important";
    for (var i = 0; i < element.children.length; i++) {
      changeFontSize(element.children[i]);
    }
  }
}

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export const attachingSystemMsg = (text) => {
  return (
    `Answer questions truthfully based on the information in sources provided below. 
  If you cannot find the answer to a question based on the sources below, respond by saying “I apologize, but I am unable to provide an answer to your question, which is out of the scope of the document uploaded. Thank you!”
 ` +
    "  Sources: " +
    text
  );
};

export const checkBase64 = (base64) => {
  if (base64 == "" || typeof base64 == "undefined") {
    return false;
  } else {
    return true;
  }
};

export const toMB = (KB) =>{
  if(KB<1000000){
    return (KB/1024).toFixed(2) + "  KB";
  }
  let sizeInMB = ((KB / (1024*1024))).toFixed(2);
  return sizeInMB + " MB";
}