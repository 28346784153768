import React from "react";
import Typography from "@mui/material/Typography";
import parse from "html-react-parser";
import styled from "@emotion/styled";
import useWindowSize from "../hook/UseWindowSize";
import SignInSignOutButton from "../ui-components/Button/SignInSignOutButton";
import Grid from "@mui/material/Grid";
import Container from "./component/Container";


const Header = styled(Typography)(
  {
    padding: "30px 45px 0px 30px;",
    color: "#757575",
    width: "100%",
  },
  (props) => ({ fontSize: props.width < 630 ? "15px" : "19px" })
);

const TNCHeader = styled(Typography)(
  {
    padding: "30px 45px 0px 30px;",
    color: "#545454",
    width: "100%",
    fontWeight: "bold",
  },
  (props) => ({ fontSize: props.size.width <= 630 ? "17px" : "24px" })
);
const ButtonContainer = styled(Grid)(
  {
    width: "100%",
    textAlign: "center",
    marginBottom: "20px",
  },
  (props) => ({
    marginTop: props.size.width > 880 ? "10px" : "20px",
  })
);

const TncContainer = styled.div(
  {
    padding: "10px 30px 30px 30px",
  },
  (props) => ({
    height: "fit-content",
    overflowY: "hidden",
  })
);

export default function PageBeforeLogin({ role = "Student" }) {
  const size = useWindowSize();
 
  return (
    <Container size={size}>
      <Header variant="h6" width={size.width}>
        Please log in with your HKU Portal account to use the ChatGPT service.
      </Header>
      <TNCHeader size={size}>
        Terms and Conditions for ChatGPT Use by HKU Staff and Students
      </TNCHeader>

      <TncContainer size={size}>
        {parse(
          `<div class="disclaimer" style="${
            "height:" +
            (size.width <= 630
              ? size.width <= 360
                ? size.height - 480
                : size.height - 430
              : size.height - 380) +
            "px"
          }"> <h3>Introduction:</h3> <p>These terms and conditions govern your usage of ChatGPT, a language model service offered by OpenAI and accessed by HKU staff and students. By using ChatGPT, you agree to comply with these terms and conditions as below.</p> <ol>
          <li><b>Use of ChatGPT</b>: The use of ChatGPT is strictly limited to teaching, learning, work, and research-related purposes. It is not permitted to use ChatGPT for personal reasons or engage in any non-work and non-studying-related activities. Please be aware that there may be a daily limit imposed on the usage of ChatGPT to ensure fair and appropriate utilization.</li>
  
          <li><b>Account Security</b>: You are responsible for maintaining the security of your ChatGPT account. Do not share your account information with anyone else. If you suspect any unauthorized use of your account, please notify us by emailing to ithelp@hku.hk immediately.</li>
  
          <li><b>Prohibited Conduct</b>: You are prohibited from abusing the ChatGPT service or engaging in any behaviour that may damage or bring negatively impact on the service. This includes but is not limited to attempting to hack or exploit the service, transmitting viruses, or engaging in any illegal activities.</li>
  
          <li><b>Accuracy of Responses</b>: ChatGPT provides answers based on its programming and language algorithms. While ITS (Information Technology Services) makes every effort to provide accurate and relevant responses, we cannot guarantee the accuracy of the answers. You are responsible for verifying the accuracy of any responses provided by the ChatGPT service.</li>
  
          <li><b>Limitations of Liability</b>: HKU and ITS are not responsible for any damages or losses arising from the use of ChatGPT. You agree to indemnify HKU and ITS from any claims, damages or losses arising from your use of ChatGPT.</li>
  
          <li><b>Termination</b>: If any abuse or suspicious usage is found under your account, ITS reserves the right to terminate your access to ChatGPT at any time without notice. We may also modify or discontinue the service at any time without notice. </li>
  
          <li><b>Privacy</b>: We respect your privacy and will only use your personal information in accordance with our <a href="https://its.hku.hk/policies-and-guidelines/privacy-policy-statements/" target="_blank">privacy policy</a>. Also, please note the <a href="https://learn.microsoft.com/en-us/legal/cognitive-services/openai/data-privacy#How%20does%20the%20Azure%20OpenAI%20Service%20process%20data?" target="_blank">Microsoft's Data, privacy, and security for Azure OpenAI Service</a>.</li>
  
          <li><b>Usage Dashboard</b>: ITS has developed a dashboard to monitor and predict the usage of ChatGPT among HKU staff and students. The figures will only be utilized for statistical purposes.</li>
  
          <li><b>Governing Law</b>: These terms and conditions are governed by and construed in accordance with the laws of Hong Kong. Any dispute arising from these terms and conditions will be subject to the exclusive jurisdiction of the Hong Kong courts.</li>
          </ol></div>`
        )}
      </TncContainer>
      <ButtonContainer size={size}>
        <SignInSignOutButton />
      </ButtonContainer>
    </Container>
  );
}
