import setting from './setting';
import systemMessage from './systemMessage';
import token from './token';
import file from './file';
import {combineReducers} from 'redux';

const reducer = combineReducers({
  setting,
  systemMessage,
  token,
  file,
});

export default reducer;