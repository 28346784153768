import React, { memo } from "react";

function Dotdotdot() {

  return (
    <div className="loading">...</div>

  );
}

export default memo(Dotdotdot);
