import {
  fontSize,
  maxResponse,
  temperature,
  topP,
  passMessageIncluded,
  isMenuOpen,
  defaultState,
  reset,
  chatDrawer,
  gptModel,
} from "../action";

const initialState = {
  fontSize: "15px",
  maxResponse: 800,
  temperature: 0.7,
  topP: 0.95,
  passMessageIncluded: 10,
  resetCounter:0,
  isMenuOpen: false,
  isDrawerOpend: true,
  gptModel:"3.5",
};

const initialStateOnlySetting = Object.fromEntries(Object.entries(initialState).filter(([key]) => {if(!key.includes('isMenuOpen')&&!key.includes('resetCounter')&&!key.includes('isDrawerOpend')&&!key.includes('gptModel')){return true}}));

export default function setting(state = initialState, action) {
  switch (action.type) {
    case fontSize:
      return { ...state, fontSize: action.payload };
    case maxResponse:
      return { ...state, maxResponse: action.payload };
    case temperature:
      return { ...state, temperature: action.payload };
    case topP:
      return { ...state, topP: action.payload };
    case passMessageIncluded:
      return { ...state, passMessageIncluded: action.payload };
    case isMenuOpen:
      return { ...state, isMenuOpen: action.payload };
    case defaultState:
      return { ...state, ...initialStateOnlySetting };
    case reset:
      const temp = state.resetCounter+1;
      //console.log(temp)
      return { ...state, resetCounter:temp};
    case chatDrawer:
      return { ...state, isDrawerOpend:action.payload };
    case gptModel:
      return { ...state, gptModel:action.payload };
    default:
      return state;
  }
}
