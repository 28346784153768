import * as React from "react";
//import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import styled from "@emotion/styled";
import { setGptModel, setDefaultState } from "../../action";
import { useSelector, useDispatch } from "react-redux";
import MenuButton from "./Component/MenuButton";
import useWindowSize from "../../hook/UseWindowSize";

const ButtonItem = styled(MenuItem)(
  {
    fontSize: "14px",
    width: "150px",
    borderRadius: "22px",
    marginBottom: "5px",
    height: "44px",
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
    alignItems: "center",
    //backgroundColor: "#218514",
    textAlign: "center",
    //pointerEvents: "none",
    lineHeight: "1",
  },
  (props) => ({
    backgroundColor: props.backgroundcolor,
    color: props.color,
    "&:hover": {
      backgroundColor: props.hovercolor,
    },
    "&:hover:after": {
      backgroundColor: props.hovercolor,
    },
  })
);

export default function ChoiceMenu({ blur }) {
  const { gptModel } = useSelector((state) => state.setting);
  const { studentOrNot } = useSelector((state) => state.token);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const size = useWindowSize();
  const dispatch = useDispatch();

  return (
    <div style={{ width: "100%" }}>
      <MenuButton
        open={open}
        handleClick={handleClick}
        gptModel={gptModel}
        studentOrNot={studentOrNot}
        blur={blur}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          width: size.width > 1200 ? "70%" : size.width < 599 ? "100%" : "90%",
          margin: "0 auto",
          "& .MuiPaper-root": {
            top:
              size.width < 500
                ? size.height - 230 + "px!important"
                : size.height - 200 + "px!important",
            backgroundColor: "transparent",
            boxShadow: "revert",
            left: "0!important",
            right: "0!important",
            "& ul": {
              width: "fit-content",
              margin: "0 0 0 auto",
            },
          },
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <ButtonItem
          hovercolor={"rgb(30 162 130)"}
          backgroundcolor={"#218514"}
          color={"white"}
          onClick={() => {
            dispatch(setGptModel("4"));
            dispatch(setDefaultState());
            handleClose();
          }}
        >
          GPT-4
          <br />
          {studentOrNot ? "" : "Limited Access"}
        </ButtonItem>
        <ButtonItem
          hovercolor={"#c8a5f8"}
          backgroundcolor={"#A730D0"}
          color={"white"}
          onClick={() => {
            dispatch(setGptModel("3.5"));
            dispatch(setDefaultState());
            handleClose();
          }}
          sx={{
            "&::after": {
              position: "absolute",
              bottom: "-5px",
              right: "20px",
              width: "20px",
              height: "20px",
              content: '""',
              backgroundColor: "#A730D0",
              transform: "rotate(45deg)",
              zIndex: "-1",
              webkitTransform: "rotate(45deg)",
              MozTransform: "rotate(45deg)",
              msTransform: "rotate(45deg)",
              OTransform: "rotate(45deg)",
            },
          }}
        >
          GPT-3.5
        </ButtonItem>
      </Menu>
    </div>
  );
}
