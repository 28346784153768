import React from "react";
import styled from "@emotion/styled";

const Container = styled.div(
  {
    //overflow:"hidden",
    maxWidth: "1026px",
  },
  (props) => ({
    border: props.size.width > 880 && !props.auth ? "1px solid #D3D3D3" : "0px",
    borderTop: props.auth? "0px":"1px solid #D3D3D3",
    background: props.auth ? "transparent" : "white",
  })
);

export default Container;
