import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import MuiInput from "@mui/material/Input";
import { useSelector, useDispatch } from "react-redux";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import parse from "html-react-parser";

import {
  setPassMessageIncluded,
  setTopP,
  setTemperature,
  setMaxResponse,
} from "../../action";

const Input = styled(MuiInput)`
  width: 42px;
  :after {
    content: none;
  }
  :before {
    content: none;
  }
`;
const InfoIcon = styled(InfoOutlinedIcon)`
  margin-top: 15px;
  width: 35px;
`;

export default function InputSlider({ label, range, info }) {
  const settings = useSelector((state) => state.setting);

  const dispatch = useDispatch();

  const [value, setValue] = React.useState(() => {
    switch (label) {
      case "Max response":
        return settings.maxResponse;
        break;
      case "Temperature":
        return settings.temperature;
        break;
      case "Top P":
        return settings.topP;
        break;
      case "Past messages included":
        return settings.passMessageIncluded;
        break;
    }
  });

  React.useEffect(() => {
    switch (label) {
      case "Max response":
        dispatch(setMaxResponse(value));
        break;
      case "Temperature":
        dispatch(setTemperature(value));
        break;
      case "Top P":
        dispatch(setTopP(value));
        break;
      case "Past messages included":
        dispatch(setPassMessageIncluded(value));
        break;
    }
  }, [value]);

  React.useEffect(() => {
    //console.log(settings.resetCounter);
    setValue(() => {
      switch (label) {
        case "Max response":
          return settings.maxResponse;
          break;
        case "Temperature":
          return settings.temperature;
          break;
        case "Top P":
          return settings.topP;
          break;
        case "Past messages included":
          return settings.passMessageIncluded;
          break;
      }
    });
  }, [settings.resetCounter]);

  const handleSliderChange = (event, val) => {
    setValue(val);
  };

  const handleInputChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Grid
        container
        spacing={2}
        alignItems="center"
        sx={{ marginLeft: "0px", flexDirection: "row" }}
      >
        <Grid container alignItems="center" sx={{ width: "83%" }}>
          <Typography
            id="input-slider"
            gutterBottom
            sx={{
              marginBottom: "-10px",
              color: "rgb(30 162 130)",
              fontSize: settings.fontSize,
            }}
            component={"span"}
          >
            {label}
          </Typography>

          <div className="tooltip">
            <InfoIcon sx={{ cursor: "pointer" }} />
            <span className="tooltiptext">
              {parse(
                `<span style="font-size:${
                  settings.fontSize.replace("px", "") * 1 - 2 + "px"
                }">${info}</span>`
              )}
            </span>
          </div>
        </Grid>
        <Grid
          container
          alignItems="center"
          sx={{
            color: "rgb(30 162 130)",
            width: "17%",
            textAlign: "right",
            fontSize: settings.fontSize,
          }}
        >
          {/*value*/}
          <Input
            value={value}
            sx={{
              border: " 0px solid rgb(30 162 130)",
              color: "rgb(30 162 130)",
              width: "70px",
              height: "40px",
              textAlign: "center",
              "&:hover":{
                borderBottom: " 2px solid rgb(30 162 130)",
                borderRadius: "0px",
              }

            }}
            size="small"
            type="number"
            onChange={handleInputChange}
            inputProps={{
              step:
                label == "Past messages included" || label == "Max response"
                  ? Math.ceil(
                      (range.split(":")[1] * 1 - range.split(":")[0] * 1) / 100
                    )
                  : (range.split(":")[1] * 1 - range.split(":")[0] * 1) / 100,
              min: range.split(":")[0] * 1,
              max: range.split(":")[1] * 1,
              type: "number",
              "aria-labelledby": "input-slider",
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs>
          <Slider
            value={value}
            type={"number"}
            onChange={handleSliderChange}
            aria-labelledby="input-slider"
            min={range.split(":")[0] * 1}
            max={range.split(":")[1] * 1}
            step={
              label == "Past messages included" || label == "Max response"
                ? Math.ceil(
                    (range.split(":")[1] * 1 - range.split(":")[0] * 1) / 400
                  )
                : (range.split(":")[1] * 1 - range.split(":")[0] * 1) / 400
            }
            sx={{
              "& span": {
                color: "rgb(30 162 130)",
                "::after": {
                  background: "white",
                  width: "25px",
                  height: "25px",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                },
              },
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
