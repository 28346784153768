import React, { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";

export default function Tnc({ modelOpen }) {
  return (
    <Grid sx={{ cursor: "pointer" }}>
      <img
        src={"/tnc.svg"}
        className="App-logo"
        alt="logo"
        onClick={() => {
          modelOpen((pre) => !pre);
        }}
      />
    </Grid>
  );
}
