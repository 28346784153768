import { tokenAvailable , studentOrNot } from "../action";

const initialState = {
  tokenLeft:0,
  studentOrNot: true
};

export default function token(state = initialState, action) {
  switch (action.type) {
    case tokenAvailable:
      return { ...state, tokenLeft: action.payload };
    case studentOrNot:
      return { ...state, studentOrNot: action.payload };
    default:
      return state;
  }
}
