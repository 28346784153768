import { useSelector, useDispatch } from "react-redux";

export default function EmptyChat() {
  const fontSize = useSelector((state) => state.setting.fontSize);

  return (
    <div
      className="empty-chat"
      id="empty-chat"
      style={{ height: "fit-content", fontSize: fontSize }}
    >
      <div className="empty-chat-icon" style={{ textAlign: "center" }}>
        <img src={"robotEmptyChat.png"} alt="robotEmptyChat" />
      </div>
      <div className="empty-chat-text">
        <h2>
          <b>Start chatting</b>
        </h2>
        <h3>Chat with ChatGPT by sending queries below.</h3>
      </div>
    </div>
  );
}
