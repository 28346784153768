import { LogLevel } from "@azure/msal-browser";

//WEBSITE_PORT 3000

//WEBSITES_CONTAINER_START_TIME_LIMIT 1800


// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
//const isIE = msie > 0 || msie11 > 0;
//const isEdge = msedge > 0;
//const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito
// Config object to be passed to Msal on creation
//pm2 serve /home/site/wwwroot --no-daemon

const redirectUri = window.location.origin;

export const msalConfig = {
    auth: { 

        // Prod

        //clientId: "b5c2e510-4a17-4feb-b219-e55aa5b74144",
        //clientId: "40f06189-3f6c-4e01-ab63-a09f6f2160e7",
        clientId: process.env.REACT_APP_CILENTID,
        authority: process.env.REACT_APP_AUTHORIZATION,
        //authority: "https://login.microsoftonline.com/42f9b54e-2477-41ba-bf09-7a0d2a83ff09",
        redirectUri: redirectUri,
        
    },
    cache: {
        //cacheLocation: "localStorage",
        //storeAuthStateInCookie: isIE || isEdge || isFirefox
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        //allowNativeBroker: false, // Disables WAM Broker
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        //console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            }
        }
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    scopes: ["User.Read"]
};

const tokenRequest = {
    scopes: ["User.Read", "Mail.Read"],
    forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};