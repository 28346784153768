import React from "react";
import { useSelector } from "react-redux";
import QuestionMarkOutlinedIcon from "@mui/icons-material/QuestionMarkOutlined";
import { getRemainingDays } from "../../utils/Tools";
import parse from "html-react-parser";
import styled from "@emotion/styled";

const MonthlyTokenDisplay = () => {
  const { token, setting ,file } = useSelector((state) => state);
  const { tokenLeft, studentOrNot } = token;
  const { fontSize } = setting;
  const { extractedText , fileNameRemaining } = file;

  const Container = styled.div(
    ({ theme }) => `
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 100%;
    color: #545454;
    background: white;
    padding-left: 20px;
    padding-bottom:${extractedText != "" && fileNameRemaining ? "30px" : "0px"};
    position: relative;
    pointer-events: ${studentOrNot ? "auto" : "none"};
  `
  );
  const Span = styled.span(
    ({ theme }) => `
    font-size: ${fontSize.replace("px", "") * 1 - 2 + "px"};
    padding-right: 5px;
    opacity: ${studentOrNot ? 1 : 0};
    
  `
  );

  const SpanToolTip = styled.span(
    ({ theme }) => `
    font-size: ${fontSize};
    padding-right: 5px;
    font-weight: bold;
    opacity: ${studentOrNot ? 1 : 0};
    
  `
  );
  const TooltipContainer = styled.span(
    ({ theme }) => `
    
    opacity: ${studentOrNot ? 1 : 0};
    background-color: white;
    height: 17px;
    width: 17px;
    border-radius: 50%;
    margin-bottom: 5px;
    border: 1px solid #545454;

  `
  );

  const Tooltiptext = styled.div(
    ({ theme }) => `
    
    visibility: hidden;
    width: fit-content;
    background-color: #545454;
    color: #fff;
    border-radius: 0px!important;
    padding: 5px 0;
    position: absolute;
    border-radius: 0px;
    z-index: 1;
    top: -30px!important;
    right: 25%!important;
    width: 150px!important;
    position: absolute;
    opacity: ${studentOrNot ? 1 : 0};
  `
  );

  const QuestionMarkOutlined = styled(QuestionMarkOutlinedIcon)`
    cursor: pointer;
    width: 0.6em;
    height: 0.6em;
    vertical-align: top;
    color: #545454;
  `;
  return (
    <Container>
      <Span>Token{tokenLeft > 1 ? "s" : ""} left: </Span>{" "}
      <SpanToolTip>{tokenLeft}</SpanToolTip>
      <TooltipContainer className="tooltip">
        <QuestionMarkOutlined />
        <Tooltiptext className="tooltiptext">
          {parse(
            `<span style="font-size:${
              fontSize.replace("px", "") * 1 - 2 + "px"
            }">Renew in <b>${getRemainingDays()}${
              getRemainingDays() * 1 > 1 ? " days" : " day"
            }</b></span>`
          )}
        </Tooltiptext>
      </TooltipContainer>
    </Container>
  );
};

export default React.memo(MonthlyTokenDisplay);
