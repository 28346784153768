import React, { useContext, useEffect, useState } from "react";
import { ChatHistContext } from "../context";
import PageBeforeLogin from "./PageBeforeLogin";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "./component/Container";
import useWindowSize from "../hook/UseWindowSize";
import { validTenants } from "../utils/AuthTools";

export function Auth({ role = "Student" }) {
  const [initialLoading, setInitialLoading] = useState(true);
  const { token, fetchTokenLoading, backToAuth } = useContext(ChatHistContext);
  const navigate = useNavigate();
  const size = useWindowSize();

  useEffect(() => {
    if (token != "" && token != null && !fetchTokenLoading && !backToAuth) {
      navigate("/home");
    }
    if (
      token != "" &&
      token != null &&
      validTenants.indexOf(token.tenantId) <= -1
    ) {
      navigate("/notAllowed");
    }
  }, [token, fetchTokenLoading, navigate]);

  if (fetchTokenLoading) {
    return (
      <Container
        size={size}
        auth
        style={{ width: "100%", textAlign: "center" }}
      >
        <CircularProgress sx={{ marginTop: "25%" }} color="inherit" />
      </Container>
    );
  }

  return <PageBeforeLogin role={role} />;
}
