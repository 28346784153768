import React, { memo, useRef, useEffect } from "react";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import { Container } from "@mui/material";
import Grid from "@mui/material/Grid";
import { ChatBox } from "mui-chat-box";
import ChatList from "./ChatList";

const ChatRoom = ({
  userQuestion,
  gptAnwser,
  setFileDialogOpen,
  setFileDialogContent,
}) => {
  const Contents = styled(ChatBox)(() => ({
    flexDirection: "row",
  }));

  useEffect(() => {
    if (messageRef.current) {
      const latestBlock =
        document.querySelectorAll(".block")[
          document.querySelectorAll(".block").length - 1
        ];
      latestBlock.scrollIntoView();
    }
  }, [gptAnwser]);

  const messageRef = useRef();
  return (
    <Grid container spacing={1} ref={messageRef}>
      <Container
        sx={{
          paddingTop: "60px",
        }}
      >
        <Contents>
          <ChatList
            key={"chatRoom"}
            location={"chatRoom"}
            questions={userQuestion}
            anwsers={gptAnwser}
            setFileDialogOpen={setFileDialogOpen}
            setFileDialogContent={setFileDialogContent}
          />
        </Contents>
      </Container>
    </Grid>
  );
};

export default memo(ChatRoom);
