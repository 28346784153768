import React, { useContext, useEffect, useState } from "react";
import AttachEmailOutlinedIcon from "@mui/icons-material/AttachEmailOutlined";
import { ChatHistContext } from "../../context";
import { emailSend } from "../../utils/Tools";
import Grid from "@mui/material/Grid";

export default function EmailSending() {
  const { chatHist, userQuestion, gptAnwser } = useContext(ChatHistContext);

  //return <AttachEmailOutlinedIcon onClick={()=>{emailSend(chatHist)}} sx={{cursor:"pointer"}}/>

  return (
    <Grid sx={{ cursor: "pointer" }}>
      <img
        src={"/email.svg"}
        className="App-logo"
        alt="logo"
        onClick={() => {
          emailSend(chatHist, userQuestion, gptAnwser);
        }}
      />
    </Grid>
  );
}
