import * as React from "react";
import Alert from "@mui/material/Alert";
import LoopIcon from "@mui/icons-material/Loop";
import Grid from "@mui/material/Grid";
import { ChatHistContext } from "../context";
import useWindowSize from "../hook/UseWindowSize";

export const TimeOutAlert = () => {
  const { setTimeLeft, timeLeft, setTimeLeftWarning } =
    React.useContext(ChatHistContext);

  const size = useWindowSize();
  return (
    <Grid
      sx={{
        width: "100%",
        borderLeft:  size.width < 500?"0px": "1px solid #D3D3D3",
        borderRight:  size.width < 500?"0px":"1px solid #D3D3D3",
      }}
    >
      <Alert
        sx={{
          width: "220px",
          fontFamily: "Roboto",
          margin: "10px auto 15px auto",
          background: "#d8d8df",
          borderRadius: "30px",
          color: "white",
          fontWeight: "bold",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "& .MuiAlert-message": {
            display: "flex",
            alignItems: "center",
          },
          "& svg": {
            cursor: "pointer",
          },
          "& .MuiAlert-icon": {
            display: "none",
          },
          "& > .MuiAlert-message": {
            paddingTop: "0px",
            paddingBottom: "0px",
          },
        }}
        severity="warning"
      >
        New topic starts in {timeLeft}
        <LoopIcon
          onClick={() => {
            setTimeLeft(600);
            setTimeLeftWarning(false);
          }}
        />
      </Alert>
    </Grid>
  );
};
