
import { UrlString } from "@azure/msal-common";

export const validTenants =  ["42f9b54e-2477-41ba-bf09-7a0d2a83ff09", "e80d8e75-52b9-4839-a358-87abb93b3567"];
//export const validTenants =  [ "e80d8e75-52b9-4839-a358-87abb93b3567"];

export const isAuthPopup = () => {
    const hash = window.location.hash;
    return UrlString.hashContainsKnownProperties(hash);
  };
  
