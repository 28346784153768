import React from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { SignInButton } from "./SignInButton";
import { SignOutButton } from "./SignOutButton";
import { InteractionStatus } from "@azure/msal-browser";
import { ChatHistContext } from "../../context";

const SignInSignOutButton = () => {
    const { inProgress } = useMsal();
    //const isAuthenticated = useIsAuthenticated();
    const { token } = React.useContext(ChatHistContext);

    if ((inProgress != InteractionStatus.Startup && inProgress != InteractionStatus.HandleRedirect)||(token == ''|| token == null)){
        return <SignInButton/>;
    } else {
        return null;
    }
}

export default SignInSignOutButton;