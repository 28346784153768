export async function fetchCompletion(endpoint, options) {
  return await fetch(endpoint, options).then((response) => {
    if (response.statusCode * 1 >= 400) {

      if(typeof response.error != "undefined"){
        return {
          statusCode: response.statusCode,
          message: response.error.message,
        };
      }
      return {
        statusCode: response.statusCode,
        message: response.message,
      };
    }
    return response.json();
  });
}
