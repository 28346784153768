import React, { useContext, useEffect, useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ChatHistContext } from "../../context";
import { copy } from "../../utils/Tools";
import Grid from "@mui/material/Grid";
import { Opacity } from "@mui/icons-material";

export default function SendEmail() {
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    if (disable) {
      setTimeout(() => {
        setDisable(false);
      }, 1500);
    }
  }, [disable]);

  const { chatHist, userQuestion, gptAnwser } = useContext(ChatHistContext);

  return (
    <Grid
      sx={{
        cursor: "pointer",
        opacity: disable ? 0.2 : 1,
        pointerEvent: disable ? "none" : "auto",
      }}
    >
      <img
        src={"/copy.svg"}
        className="App-logo"
        alt="logo"
        onClick={() => {
          setDisable(true);
          copy(chatHist, userQuestion, gptAnwser);
        }}
      />
    </Grid>
  );
}
