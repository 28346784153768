import React from "react";
import Grid from "@mui/material/Grid";

import Container from "./component/Container";
import useWindowSize from "../hook/UseWindowSize";

export default function NotAllowed() {
  const size = useWindowSize();

  return (
    <Container
      size={size}
      auth
      style={{
        width: "100%",
        textAlign: "center",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
      }}
    >
      <Grid
        item
        xs={12}
        sx={{
          fontSize: 25,
        }}
      >
        Sorry! You are not allowed to use this service.
      </Grid>
    </Container>
  );
}
