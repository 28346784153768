import * as React from "react";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import styled from "@emotion/styled";
import { useDispatch } from "react-redux";
import { toBase64 } from "../../utils/Tools";
import { toMB } from "../../utils/Tools";

const Icon = styled(AttachFileIcon)`
  cursor: pointer;
`;

const FileInput = styled.input`
  display: none;
`;

function Attachment({
  isLoading,
  setIsLoading,
  setFileName,
  setFileOrNot,
  setFileBase64,
  setDocumentID,
  setExtractedText,
  setFileFormat,
  setFileSize,
  setFileUploadStatus,
  setIsUploading,
  setFileNameRemaining,
  cleanFileContent
}) {
  const dispatch = useDispatch();
  React.useEffect(() => {
    async function attactmentFunction() {
      document.querySelector("#documentUpload").onchange = async function () {
        //console.log(this.value)
        if (!this.files) {
          console.error(
            "This browser doesn't seem to support the `files` property of file inputs."
          );
        } else {
          let file = this.files.length > 0 && this.files[0];
          if (typeof file.size != "undefined") {
            if (file.size > 3000000 ) {
              setIsUploading(true);
              setFileUploadStatus("File size exceeded 3MB !");

            }else {
              cleanFileContent();
              //const myRenamedFile = new File([file], file.name.replace(/[.](?=.*[.])/g, "_"));
              let base64encode = await toBase64(file);
              dispatch(setFileName(this.value));
              dispatch(setFileBase64(base64encode));
              dispatch(setFileOrNot(true));
              dispatch(setFileSize(toMB(this.files[0].size)));
              dispatch(setFileFormat(this.value.replace(/[.](?=.*[.])/g, "_").split(".")[1]));
              dispatch(setFileNameRemaining(true));

            }
          }
        }
        this.value = "";
      };
    }
    attactmentFunction();
  }, []);

  return (
    <div>
      <Icon
        onClick={() => {
          if (!isLoading) {
            //dispatch(setDocumentID(""));
            //dispatch(setExtractedText(""));
            //dispatch(setFileName(""));
            //dispatch(setFileOrNot(false));
            //dispatch(setFileBase64(""));
            //dispatch(setFileSize(""));
            //dispatch(setFileFormat(""));
            document.querySelector("#documentUpload").click();
          }
        }}
      />
      <FileInput
        type="file"
        accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.presentationml.presentation"
        id="documentUpload"
      ></FileInput>
    </div>
  );
}

export default React.memo(Attachment);
