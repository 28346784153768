import { useIsAuthenticated } from "@azure/msal-react";
import Typography from "@mui/material/Typography";
import NavBar from "../ui-components/NavBar";
import styled from "@emotion/styled";
import useWindowSize from "../hook/UseWindowSize";

const OuterContainer = styled.div(
  { width: "100%", background: "#f9f9f9!important" },
  (props) => ({ height: props.height + "px" })
);

const InnerContainer = styled.div(
  {
    margin: "0 auto",
    height: "100%",
  },
  (props) => ({
    maxWidth:
      props.windowSize > 1200 ? "70%" : props.windowSize < 880 ? "100%" : "90%",
  })
);

export const PageLayout = (props) => {
  const size = useWindowSize();
  const isAuth = useIsAuthenticated();
  return (
    <OuterContainer>
      <NavBar />
      <InnerContainer isAuth={isAuth} windowSize={size.width}>
        {props.children}
      </InnerContainer>
    </OuterContainer>
  );
};
