import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import styled from "@emotion/styled";
import React, { useContext } from "react";
import { ChatHistContext } from "../context";
import { useSelector } from "react-redux";

const InfoIcon = styled(InfoOutlinedIcon)`
  margin-left: 5px;
  margin-top: 10px;
  width: 15px;
  fontSize:12px
`;

export default function Token() {
  const { tokenUsed } = useContext(ChatHistContext);
  const { fontSize } = useSelector((state) => state.setting);

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      sx={{ marginLeft: "0px", background: "#f9f9f9", marginTop: "-30px" }}
    >
      <Typography sx={{ marginBottom: "-10px", fontSize: "14px" ,fontSize: fontSize  }}>
        Tokens Used
      </Typography>
      <Tooltip
        title="Azure OpenAI processes text by breaking it down into tokens. Tokens can be words or just chunks of characters. For example, the word “hamburger” gets broken up into the tokens “ham”, “bur” and “ger”, while a short and common word like “pear” is a single token. Many tokens start with a whitespace, for example “ hello” and “ bye”. The total number of tokens processed in a given request depends on the length of your input, output and request parameters. The quantity of tokens being processed will also affect your response latency and throughput for the models."
        sx={{ fontSize: fontSize }}
      >
        <InfoIcon />
      </Tooltip>
      <Typography sx={{ marginBottom: "-10px", fontSize: "14px" }}>
        :
      </Typography>
      <Typography sx={{ marginBottom: "-10px", fontSize: "14px" }}>
        {tokenUsed}
      </Typography>
    </Grid>
  );
}
