import styled from "@emotion/styled";
import Grid from "@mui/material/Grid";
import {  useDispatch } from "react-redux";

const Container = styled.div(
  {
    fontWeight: "bold!important",
    width: "fit-content!important",
    fontSize: "10px",
    border: "1px solid #E4E4E4!important",
    borderRadius: "15px!important",
    background: "#F7F7F8!important",
    left: "5%!important",
    color: "#777!important",
    marginBottom: "10px!important",
    width: "fit-content!important",
    maxWidth: "60%!important",
    alignItems: "center!important",
    cursor: "pointer"
  },
  (props) => ({
    padding:
      props.location == "Textarea" ? "8px 20px!important" : "5px 5px!important",
    marginBottom:
      props.location == "Textarea" ? "10px!important" : "0px!important",
    marginRight: props.location == "Textarea" ? "inherited" : "5px!important",
    marginTop:
      props.location == "Textarea" ? "10px!important" : "0px!important",
    maxWidth: props.location == "Textarea" ? "60%!important" : "100%!important",
    marginLeft: props.width >= 360 ? "10px!important" : "0px!important",
  })
);

const FileIcon = ({location}) => {
  return (
    <svg
      style={{ marginRight: location!="Textarea"?"-5px":"10px" }}
      width="12"
      height="18"
      viewBox="0 0 15 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 0H0V18H15V4.5L10.5 0ZM10.5 2.25L12.75 4.5H10.5V2.25ZM1.5 16.5V1.5H9V6H13.5V16.5H1.5Z"
        fill="#545454"
      />
    </svg>
  );
};

const CloseIcon = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0C4.4775 0 0 4.4775 0 10C0 15.5225 4.4775 20 10 20C15.5225 20 20 15.5225 20 10C20 4.4775 15.5225 0 10 0ZM13.4592 14.9525L10.0067 11.5342L6.57583 15L5.04833 13.4725L8.46833 10.0083L5 6.57583L6.5275 5.04833L9.99 8.46667L13.4117 5L14.9525 6.54083L11.5358 9.99167L15 13.4117L13.4592 14.9525Z"
        fill="#545454"
      />
    </svg>
  );
};

const IconContainer = styled.div(
  ({ theme }) => `
  position: absolute;
  right: -25px;
  top: -10px;
  background: #F7F7F8!important;
`
);

const TextContainer = styled.div(
  ({ theme }) => `
  white-space: nowrap!important;
  overflow: hidden!important;
  text-overflow: ellipsis!important;
  background: #F7F7F8!important;
  width:100px!important
`
);

const FileNameContainer = styled.span(
  ({ theme }) => `
  padding-left: 10px;
`
);

export const FileNameContainter = ({
  fileName,
  fileSize,
  fileFormat,
  fileBase64,
  clear = null,
  location = "Textarea",
  width,
  setFileDialogOpen,
  setFileDialogContent
}) => {
  const dispatch = useDispatch();
  return (
    <Container
      location={location}
      width={width}
      onClick={(e) => {
        console.log(e)
        if(e.target.outerHTML.indexOf("<path")<=-1){
          dispatch(setFileDialogOpen(true))
          dispatch(setFileDialogContent(fileFormat,fileName,fileSize,fileBase64))
        }
        
      }}
    >
      <Grid
        container
        sx={{
          position: "relative",
          background: "#F7F7F8!important",
          marginLeft: location == "Textarea" ? "inherited" : "5px!important",
          alignItems: "center",
          width: location == "Textarea" ? "inherited" : "150px",
          height: location == "Textarea" ? "inherited" : "50px",
          marginTop: location == "Textarea" ? "inherited" : "0px!important",
        }}
      >
        {location == "Textarea" && (
          <IconContainer className={"clearFile"}>
            <a onClick={clear}>
              <CloseIcon />
            </a>
          </IconContainer>
        )}
        <FileIcon location={location}/>
        <TextContainer style={{}}>
          {location == "Textarea" ? (
            fileName
          ) : (
            <FileNameContainer>{fileName}</FileNameContainer>
          )}
          <br />
          <Grid
            container
            sx={{
              fontWeight: "100",
              background: "#F7F7F8!important",
              alignItems: "center",
              marginTop:
                location == "Textarea" ? "inherited" : "-5px!important",
            }}
          >
            {" "}
            <Grid sx={{display:"none"}}>{123}</Grid>
            <Grid sx={{display:"none"}}>{123}</Grid>
            <Grid sx={{display:"none"}}>{123}</Grid>
            <Grid
              sx={{
                marginRight: "5px",
                background: "#F7F7F8!important",
                margin: "5px 5px 0 0px!important",
              }}
            >
              {fileFormat.toUpperCase()}
            </Grid>{" "}
            <Grid
              sx={{
                background: "#F7F7F8!important",
                marginTop:
                  location == "Textarea" ? "inherited" : "10px!important",
                margin: "5px 0 0 0px!important",
                marginLeft: "0px!important",
              }}
            >
              
              {fileSize}
            </Grid>
           
          </Grid>
          
        </TextContainer>
        {location != "Textarea" && <img style={{paddingLeft:"5px"}} src="/icon_preivew.svg"/>}
      </Grid>
    </Container>
  );
};
