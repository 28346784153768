import { loginRequest, graphConfig, msalConfig } from "../authConfig";
import { msalInstance } from "../index";

export async function getTokenResponse() {
  const account = msalInstance.getActiveAccount();

  if (account) {
    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: account,
    });
    return response;
  }
  return null;
  
}

export async function callMsGraph(accessToken) {
  if (!accessToken) {
    const account = msalInstance.getActiveAccount();
    if (!account) {
      throw Error(
        "No active account! Verify a user has been signed in and setActiveAccount has been called."
      );
    }
    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: account,
    });
    accessToken = response.accessToken;
  }
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;
  headers.append("Authorization", bearer);
  const options = {
    method: "GET",
    headers: headers,
  };

  return fetch(graphConfig.graphMeEndpoint, options)
    .then((response) => {/*console.log(response)*/})
    .catch((error) => console.log(error));
}

