import React, { memo, useState, useEffect } from "react";
import FileViewer from "react-file-viewer";

function FilePreview({ type = "pdf", fileBase64, fileName, windowSize }) {

  var dataType;
  switch (type) {
    case "pdf": {
      dataType = "application/pdf";
      break;
    }
    case "xlsx": {
      dataType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      break;
    }
    case "docx": {
      dataType =
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      break;
    }
    default: {
      dataType = "";
      break;
    }
  }

  return type == "pdf"? (
    <embed
      style={{ width: "100%", height: windowSize + "px" }}
      src={`data:${dataType};base64,${fileBase64}`}
    />
  ) : //<FileViewer
  //  className="file-viewer-style"
  //  key={fileNameTrim}
  //  fileType={"pdf"}
  //  filePath={"data:" + dataType + ";base64," + fileBase64}
  ///>

  type == "pptx" ? (
    "Sorry the Microsoft Power Point Format cannot be previewed"
  ) : (
    <FileViewer
      key={fileName}
      fileType={type}
      filePath={"data:" + dataType + ";base64," + fileBase64}
      height={200}
    />
  );
  //);
}

export default memo(FilePreview);
