// If you need to support IE11 uncomment the imports below
//import "react-app-polyfill/ie11";
//import "core-js/stable";
import App from "./App";
// MSAL imports
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./styles/theme";
import thunk from "redux-thunk";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import reducer from "./reducer";
import ChatHistProvider from "./context";
import { isAuthPopup } from "./utils/AuthTools";
const store = createStore(reducer, applyMiddleware(thunk));

export const msalInstance = new PublicClientApplication(msalConfig);

const getAccounts = new Promise((resolve, reject) => {
  // Default to using the first account if no account is active on page load
  if (
    !msalInstance.getActiveAccount() &&
    msalInstance.getAllAccounts().length > 0
  ) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents();

  msalInstance.addEventCallback((event) => {
    if (event.eventType == EventType.LOGIN_SUCCESS && event.payload.account) {
      const account = event.payload.account;
      msalInstance.setActiveAccount(account);
    }
  });
  resolve("Success");
});

/**
 * We need to determine if we are in the auth popup
 * If so, we need to render a simple message
 * Otherwise, we render the app
 * [MSAL uses]{@link (https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/src/interaction_client/PopupClient.ts#L343)} "UrlString.hashContainsKnownProperties" method to validate the hash
 * @returns true if the current window is an auth popup
 */


const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);
if (!isAuthPopup()) {
  getAccounts.then((value) => {
    if (value == "Success") {
      root.render(
        <Router>
            <Provider store={store}>
              <ChatHistProvider>
                <App pca={msalInstance} />
              </ChatHistProvider>
            </Provider>
        </Router>
      );
    }
  });
}
